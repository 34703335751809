[
  { "label": "Apple", "segment": "technology", "ring": "high" },
  { "label": "Red Hat", "segment": "technology", "ring": "high" },
  { "label": "Microsoft", "segment": "technology", "ring": "high" },
  { "label": "GraphQl", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": ".Net", "segment": "technology", "ring": "high" },
  { "label": "GoLang", "segment": "technology", "ring": "high" },
  { "label": "React", "segment": "framework", "ring": "high" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },
  { "label": "Potato", "segment": "vegetable", "ring": "medium" },

  { "label": "Blackberry", "segment": "technology", "ring": "low" },
  { "label": "Mango", "segment": "fruit", "ring": "medium" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Grapes", "segment": "fruit", "ring": "low" },
  { "label": "Orange", "segment": "fruit", "ring": "high" },
  { "label": "Orange", "segment": "fruit", "ring": "high" },
  { "label": "Orange", "segment": "fruit", "ring": "high" },
  { "label": "Orange", "segment": "fruit", "ring": "high" },
  { "label": "Drupal 8", "segment": "framework", "ring": "high" },
  { "label": "Drupal 8", "segment": "framework", "ring": "high" },
  { "label": "Drupal 8", "segment": "framework", "ring": "high" },
  { "label": "Drupal 8", "segment": "framework", "ring": "high" },
  { "label": "Drupal 8", "segment": "framework", "ring": "high" },
  { "label": "Drupal 8", "segment": "framework", "ring": "high" },
  { "label": "Drupal 8", "segment": "framework", "ring": "high" },
  { "label": "Drupal 8", "segment": "framework", "ring": "high" },
  { "label": "Drupal 8", "segment": "framework", "ring": "high" },
  { "label": "Magento", "segment": "framework", "ring": "low" },
  { "label": "Magento", "segment": "framework", "ring": "low" },
  { "label": "Magento", "segment": "framework", "ring": "low" },
  { "label": "Magento", "segment": "framework", "ring": "low" },
  { "label": "Magento", "segment": "framework", "ring": "low" },
  { "label": "Magento", "segment": "framework", "ring": "low" }
]
